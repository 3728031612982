
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Rose&display=swap');
@font-face {
  font-family: 'PerfectDark';
  src: url('../../../public/images/fontsandpngs/pdark.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.home-main::-webkit-scrollbar {
  display: none;
}
.home-main{
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: hidden;
}

body{
overflow: hidden;
}

.homepage-hp {
  height: 100vh;
  width: 100%;
  overflow: hidden;

}



.background_image-hp {
  background-image: url('../../../public/images/fontsandpngs/starrybg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  left: 0;
  top: 5rem;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.exodus-hp {
  font-family: 'PerfectDark', sans-serif; /* Use the custom font */
  font-size: 72px; /* Increase font size */
  font-weight: bold; /* Apply bold style */
  margin-top: 100px;
  text-shadow: 0px 0px 8.382px #865EFF, 0px 0px 16.764px #865EFF, 0px 0px 58.674px #865EFF, 0px 0px 117.348px #865EFF, 0px 0px 201.168px #865EFF, 0px 0px 352.044px #865EFF;
  color: #fff;
  position: relative;
  z-index: 2;
  text-align: center; /* Center the text */
  letter-spacing: 10px;

}

.timer-container {
  display: flex;
  justify-content: center;
  text-align: center;
  position: absolute;
  bottom: 2.5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

}


.timer-hp {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  border-collapse: collapse;
  width: 100%;
  border-radius: 20px;
  opacity: 0.8;
  background: radial-gradient(50% 50% at 50% 50%, rgba(43, 35, 72, 0.50) 0%, rgba(134, 94, 255, 0.50) 100%);
  box-shadow: 0px 0px 0.63px 0px #E383FF, 0px 0px 1.26px 0px #E383FF, 0px 0px 4.41px 0px #E383FF, 0px 0px 8.82px 0px #E383FF, 0px 0px 15.12px 0px #E383FF, 0px 0px 26.46px 0px #E383FF;
  text-align: center;
  color: aliceblue;
}

.timervalue-hp {
  color: #FFF;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 111%;
  letter-spacing: -2.2px;


}

.timertitle-hp {
  color: #FFF;
  text-align: center;
  font-family: "Red Rose", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 111%;
  letter-spacing: -0.76px;
  margin-top: 5px;
}

#days{
  padding-left: 20px;
}

#sec{
  padding-right: 10px;
}

.colon {
  color: #FFF;
  font-size: 35px;
  margin-top: -10px;
  padding: 5px;
}

.maincontainer-hp {
  position: relative;
  z-index: 0;
}

.buildings-hp-div{
  transform: none;
  margin-top: -4rem;
  position: relative;
}


.spiral-vector-hp {
  width: 100px;
  height: 80px;
  flex-shrink: 0;

  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);

  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  fill: radial-gradient(50.26% 50.03% at 54.09% 50.14%, rgba(231, 83, 220, 0.00) 0%, #FFF 0%, rgba(93, 50, 222, 0.53) 20.62%, rgba(114, 55, 222, 0.45) 55%, rgba(114, 55, 222, 0.45) 62.57%);
  stroke-width: 2px;
  stroke: #FEF8F8;
  mix-blend-mode: luminosity;
  filter: drop-shadow(0px 0px 1.894px #FFF)
          drop-shadow(0px 0px 3.787px #FFF)
          drop-shadow(0px 0px 13.255px #FFF)
          drop-shadow(0px 0px 26.51px #FFF)
          drop-shadow(0px 0px 45.446px #FFF)


}

.buildings-hp{
  position: relative;
  transform: scale(1.25);
  overflow: hidden;
  z-index: 1;
}



@media only screen and (max-width: 768px) {
  .exodus-hp {
      font-size: 36px;
      margin-top: 50px;
  }

  .timer-container
  {
    font-size: 36px;
      margin-top: 50px;
  }


.timer-hp {
  font-size: 24px;
}

.timervalues-hp {
  font-size: 40px;
}

.timertitles-hp {
  font-size: 16px;
}

.spiral-vector-hp {
  width: 80px;
  height: 64px;
  top: 20%;
  transform: translate(-50%, -50%);
}


  .timer-container {
    bottom: 50px;
    width: 80%;


  }
  .buildings-hp-div{
    transform: none;
    overflow: hidden;
    margin-top: 1rem;
  }
  .buildings-hp
  {
    transform: scale(2.5);
    bottom: 0;
    height: 110vh;
    margin-top: -1.75rem;
    overflow: hidden;

  }
  .homepage-hp

  {
    max-height: 100vh;
    /* overflow: hidden; */
    overflow-y: hidden;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 380px) {
  .buildings-hp-div{
    transform: none;
    overflow: hidden;
    margin-top: -5rem;
  }
  .buildings-hp
  {
    transform: scale(2.7);
    bottom: 0;
    height: 100vh;
    margin-top: 0;
    overflow: hidden;

  }
}

@media only screen and (max-width: 370px) {
  .buildings-hp-div{
    transform: none;
    overflow: hidden;
    margin-top: -2.5rem;
  }
  .buildings-hp
  {
    transform: scale(2.7);
    bottom: 0;
    height: 100vh;
    margin-top: 0;
    overflow: hidden;

  }
}