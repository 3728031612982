@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.nav_container1 img{
  height: 80%;
}
.nav_hamburger_menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 0.5rem;
}

button {
  background: none;
  border: none;
}

.nav_menubar {
  width: 5vw;
  height: 0.2vh;
  background-color: #FFA3FD;
  margin: 15% 0;
  transition: 0.4s;
}

.nav_container {
  display: inline-flex;
  width: 100vw;
  height: 5rem;
  background-color: #37256A;
  font-family: 'Red Rose', serif;
  z-index: 3;
}

.nav_container1 {
  display: flex;
  width: 20vw;
  align-items: center;
  justify-content: space-around;
}

.nav_container2 {
  margin-left: 2vw;
  display: flex;
  width: 55vw;
  align-items: center;
  justify-content: flex-start;
  gap: 3vw;
  font-size: 1.4rem;
  color: white;
}

.nav_container3 {
  font-size: 1.5rem;
  display: flex;
  width: 25vw;
  align-items: center;
  justify-content: center;
  gap: 1.5vw;
}

.nav_container4 {
  display: none;
}

.nav_elements a {
  color: white;
  text-decoration: none;
  transition: 0.7s ease
}

.nav_elements a:hover {
  color: #FFA3FD;
}

.nav_button1 {
  padding: 0.7rem;
  font-size: 1.3rem;
  border-color: #E384FF;
  border-radius: 2rem;
  text-decoration: none;
  color: white;
  background: #E384FF;
}

.nav_button1:hover {
  background: #3c3fd7;
}

.nav_button2 {
  padding: 0.8rem;
  font-size: 1.3rem;
  border-color: #E384FF;
  border-radius: 2rem;
  color: white;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.50);
}

.nav_button2:hover {
  background: #3c3fd7;
}

.nav_button2 span {
  transform: translate(4px);
  display: inline-block;
}

.nav_elements:last-child {
  white-space: nowrap;
}

.nav_elements:last-child,
.nav_button2 {
  white-space: nowrap;
}

@media (max-width: 1200px) {


  .nav_container1 img {
    width: 10vw;
    max-height: 4rem;
  }

  .nav_container2 {
    margin-left: 1vw;
    display: flex;
    width: 50vw;
    align-items: center;
    justify-content: space-around;
    gap: 1.5vw;
    font-size: 1.1rem;
    color: white;
  }

  .nav_container3 {
    margin-left: 1vw;
    font-size: 1rem;
    display: flex;
    width: 25vw;
    align-items: center;
    justify-content: flex-end;
  }

  .nav_button1 {
    padding: 0.5rem;
    font-size: 1.2rem;
    border-color: #E384FF;
    border-radius: 2rem;
    text-decoration: none;
    color: white;
    background: #E384FF;
  }

  .nav_button2 {
    padding: 0.5rem;
    padding-top: 0.7rem;
    padding-right: 0.8rem;
    font-size: 1.2rem;
    border-color: #E384FF;
    border-radius: 2rem;
    color: white;
    text-decoration: none;
    background: rgba(0, 0, 0, 0.50);
  }
}

@media (max-width: 884px) {

  .nav_container1 img {
    width: 10vw;
    max-height: 4rem;
  }

  .nav_container2 {
    margin-left: 2vw;
    display: flex;
    width: 50vw;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5vw;
    font-size: 0.9rem;
    color: white;
  }

  .nav_container3 {
    font-size: 0.8rem;
    display: flex;
    width: 25vw;
    align-items: center;
    justify-content: flex-end;
    gap: 1vw;
  }

  .nav_button1 {
    padding: 0.5rem;
    font-size: 1.1rem;
    border-color: #E384FF;
    border-radius: 2rem;
    text-decoration: none;
    color: white;
    background: #E384FF;
  }

  .nav_button2 {
    padding: 0.5rem;
    padding-top: 0.7rem;
    padding-right: 0.7rem;
    font-size: 1.1rem;
    border-color: #E384FF;
    border-radius: 2rem;
    color: white;
    text-decoration: none;
    background: rgba(0, 0, 0, 0.50);
  }
}

@media (max-width: 724px) {


  .nav_container1 {
    padding-left: 0.1rem;
    justify-content: flex-start;
    width: 70vw;
  }

  .nav_container1 img {
    max-height: 5rem;
    width: 16vw;
  }

  .nav_exodus {
    width: 25vw;
  }

  .nav_container2 {
    display: none;
  }

  .nav_container3 {
    justify-content: flex-end;
    align-items: center;
  }

  .nav_button1 {
    padding: 0.7rem;
    font-size: 1rem;
    border-color: #E384FF;
    border-radius: 2rem;
    text-decoration: none;
    color: white;
    background: #E384FF;
  }

  .nav_button2 {
    padding: 0.7rem;
    padding-right: 0.8rem;
    padding-top: 0.9rem;
    font-size: 1rem;
    border-color: #E384FF;
    border-radius: 2rem;
    color: white;
    text-decoration: none;
    background: rgba(0, 0, 0, 0.50);
  }

  .nav_container2 {
    display: none;
  }
  .nav_hamburger_menu {
    display: flex;
  }

  .nav_container4 {
    display: none;
    /* background: linear-gradient(180deg, #865EFF 0%, #1A1826 80%);
    opacity: 0.5; */
    background: linear-gradient(180deg, rgba(134, 94, 255, 0.95) 0%, rgba(26, 24, 38, 0.95) 90%);
    /* background: rgba(134, 94, 255, 0.5); */
  }

  /* Style for vertical navbar */
  .nav_container4.vertical {
    padding-top: 1rem ;
    border-radius: 1.5rem 1.5rem 0px 0px;
    display: flex;
    flex-direction: column;
    position: Fixed;
    bottom: 0;
    width: 100vw;
    margin: 0;
    height: 40vh;
    font-size: 1.2rem;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    z-index: 100;
    /* font-weight: 400; */
  }

  .nav_elements {
    position: relative;
    top: 3%;
    margin-top: 2%;
    flex: auto;
    margin-left: 5%;
  }

  /* Style for nav_container2bar items */
  .nav_container4 a {
    color: white;
    opacity: 1;
    text-decoration: none;
    padding: 3%;
    padding-left: 2%;
  }
}

@media (max-height: 630px) {
  .nav_container4.vertical {
    padding-top: 1rem ;
    border-radius: 1.5rem 1.5rem 0px 0px;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    width: 100vw;
    margin: 0;
    height: 40vh;
    font-size: 1rem;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    /* font-weight: 400; */
  }
}

@media (max-height: 540px) {
  .nav_container4.vertical {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}