
@import url('https://fonts.googleapis.com/css2?family=Red+Rose&family=Roboto&family=Rubik+Doodle+Shadow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital@1&display=swap');
*{
    margin: 0;
    padding-top: 0%;
    box-sizing: border-box;

}

#pc{
    margin-top: 1rem;
}

.timeline_main{

    width: 100%;
    height: 100vh;
    background-image: url("../../../public/images/tl_bg.png");
    overflow: hidden;
}



.tl_container{
    margin-left:50%;
    margin-top:1%;


}
.tl_container svg{

    position: absolute;
    top:5%;
    left:8%;

}
.tl_first {
    font-size:1.875rem;
    color: #FFF;
    font-family: Century Schoolbook;
    line-height: normal;
    font-style: normal;
    font-weight: 700;

}
.timeline_h1{
    position: absolute;
    color: #C197CD;
    text-align: center;
    font-family: "Red Rose";
    font-size: 4.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 134%;

    top:10%;
    left:41.75%;

}

#mob{
    display: none;
}
@media screen and (max-width: 431px) {
    #mob{
        display: block;
    }
    #pc{
        display: none;
    }
    .tl_first {
        font-size:0.75rem;
        color: #FFF;
        font-family: Century Schoolbook;
        line-height: normal;
        font-style: normal;
        font-weight: 700;

    }
    .tl_container{
        padding-top: 40%;
        padding-right: 10%;
        overflow: hidden;
    }
    .tl_container svg{
        width: 85%;
        height: 85%;
        position: absolute;
        top:15%;
        left:8%;
    }
    .timeline_h1{
        top:10%;
        color: #C197CD;
        text-align: center;
        font-family: "Red Rose";
        font-size: 2.9rem;
        font-style: normal;
        font-weight: 400;
        line-height: 134%;
        position: absolute;
        left:27%;
        bottom:70%;
    }
}