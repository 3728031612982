.rules_wrapper {
    color: white;
    margin: 0;
    font-family: 'Al Tarikh', serif;
    width: 100vw;
    height: 100vh;
    background-color: black;
    cursor: pointer;
    position: relative;
    overflow: scroll;
}

.rules_text {
    position: relative;
    font-size: 1.5rem;
    text-align: left;
    width: 80%;
    margin: 0 auto;
    padding: 0% 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    z-index: 1;
    line-height: 4rem;
}

.rules_header {
    font-size: 4rem;
    font-weight: 650;
    text-align: left;
    padding: 2% 6% 2%;
    width: 80%;
    margin: 0 auto;
    color: #C197CD;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background-color: #f3ec78;
    background-image: linear-gradient(45deg, #C197CD 40%, #553193 55%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    animation: rainbow-text-simple-animation-rev 1.5s ease forwards;
    z-index: 1;
}

.rules_header:hover {
    animation: rainbow-text-simple-animation 1.5s ease-in forwards;
}

@keyframes rainbow-text-simple-animation-rev {
    0% {
        background-size: 650%;
    }
    40% {
        background-size: 650%;
    }
    100% {
        background-size: 100%;
    }
}

@keyframes rainbow-text-simple-animation {
    0% {
        background-size: 100%;
    }
    80% {
        background-size: 650%;
    }
    100% {
        background-size: 650%;
    }
}

.rules_images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rules_images img {
    position: absolute;
    width: 10%;
}

.rules_vector1 {
    position: absolute;
    top: 10%;
    left: 5%;
    opacity: 40%;
}

.rules_vector2 {
    position: absolute;
    top: 70%;
    right: 5%;
    opacity: 40%;
}

.rules_vector3 {
    position: absolute;
    top: 70%;
    bottom: 18%;
    left: 5%;
    opacity: 40%;
}

.rules_vector4 {
    position: absolute;
    top: 10%;
    bottom: 20%;
    right: 5%;
    opacity: 40%;
}

.rules_vector6 {
    position: absolute;
    top: 20%;
    right: 40%;
    transform: translate(0, -50%);
    width: 13%;
}

.astronaut_image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: 1;
}

@media (max-width: 900px) {
    .rules_wrapper {
        width: 100%;
        height: 100vh;
        overflow: scroll;
    }

    .rules_text {
        font-size: 1rem;
        text-align: left;
        padding-left: 16%;
        width: 95vw;
        margin: 0 auto;
        line-height:  1.6rem;
    }

    .rules_header {
        font-size: 3rem;
        padding-left: 21%;
        padding-top: 5%;
        width: 70%;
        margin: 0 auto;
    }

    .rules_images {
        display: none;
    }
}
