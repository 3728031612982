/* Anurag */
/* CSS RESETS */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* html {
  font-size: 62.5%;
} */

/*
body {
  font-family: "Red Rose", serif;
  line-height: 1;
  overflow-x: hidden;
}
*/

.section--dashboard {
  font-family: "Red Rose", serif;
  line-height: 1;
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
}

.modal_dashboard{
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
}
.team-name--dashboard{
    font-size: 1.5rem;
    font-weight: 400;
    color: #ffffff;
}

.team-dashboard-info-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 90%;
}
.team-data--dashboard{
  font-size: 1.5rem ;
    font-weight: 400;
    color: #d9d9d9;
    margin-bottom: 1rem;
}
.team-info-box--dashboard{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 90%;
}

.label-modal{
  width: 100%;
  height: fit-content;
  background: transparent;
  border: none;
  border-bottom: #ffffff 1px solid;
  color: white;
  font-size: 1.5rem;
}

.modal_button{
  margin: 10%;
  display: flex;
  flex-direction: row;
    justify-content: space-between;
  gap: 2rem;
}

.section--dashboard {
  /* padding: 14rem 5rem; */
  padding: 8.75rem 3.125rem;
  /* background-image: url(paul-volkmer-unsplash.png); */
  background: linear-gradient(
          112deg,
          rgba(104, 67, 151, 0.2) 3.52%,
          rgba(134, 94, 255, 0.07) 20.56%,
          rgba(133, 72, 232, 0.1) 25.57%,
          rgba(133, 13, 204, 0.2) 99.83%,
          rgba(139, 27, 205, 0.19) 126.34%,
          rgba(217, 217, 217, 0) 136.42%
  ),
  url(../../../public/images/background-dashboard.jpg) lightgray 50% / cover
  no-repeat;
}

.container--dashboard {
  /* max-width: 112rem; */
  max-width: 70rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 4rem; */
  gap: 2.5rem;

  font-family: "Red Rose", serif;
  color: #fff;
}

.team-outer-box--dashboard {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  /* gap: 3.2rem; */
  gap: 2rem;
  align-items: center;
}

.team-info-box--dashboard {
  /* width: 38rem;
  height: 32rem; */
  width: 23.75rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 3.2rem; */
  padding: 0 2rem;
  /* border-radius: 3rem; */
  border-radius: 1.875rem;
  background: rgba(134, 94, 255, 0.5);
  /* font-size: 2rem; */
  font-size: 1.25rem;
}

.team-buttons-box--dashboard {
  display: flex;
  align-items: center;
  /* gap: 1.2rem; */
  gap: 0.75rem;
  /* padding: 1rem 0; */
  padding: 0.625rem 0;
}

.button--dashboard {
  /* font-size: 1.8rem; */
  font-size: 1.125rem;
  color: #1a1826;
  font-weight: 700;
  background-color: #fff;
  /* padding: 1.2rem 2rem; */
  padding: 0.75rem 1.25rem;
  /* border-radius: 1rem; */
  border-radius: 0.625rem;
  cursor: pointer;
}
.details-outer-box--dashboard {
  flex-grow: 2;
  display: grid;
  grid-template-columns: 1fr 2fr;
  row-gap: 2rem; /* ((3.2 * 10) / 16) */
  column-gap: 1.25rem; /* ((2 * 10) / 16) */
  font-size: 2.125rem; /* ((3.4 * 10) / 16) */
  width: 90%;
  align-items: center;
}

.label--dashboard {
  justify-self: end; /*toggle for other view */

}

.text-field--dashboard {
  border-bottom: 1px solid #fff;
}

.btn-change-password--dashboard {
  margin-top: 1.25rem; /* ((2 * 10) / 16) */
  grid-column: 1/-1;
  justify-self: center;
}

/* Smaller Laptops 1070px*/
@media (max-width: 67em) {
  .section--dashboard {
    padding: 8.75rem 1.875rem; /* ((14 * 10) / 16) */
  }
  .team-info-box--dashboard {
    font-size: 0.875rem; /* ((1.6 * 10) / 16) */
  }
  .button--dashboard {
    font-size: 0.875rem; /* ((1.4 * 10) / 16) */
  }
  .details-outer-box--dashboard {
    font-size: 1.75rem; /* ((2.8 * 10) / 16) */
  }
  .team-info-box--dashboard {
    width: 20rem; /* ((32 * 10) / 16) */
    height: 16.25rem; /* ((26 * 10) / 16) */
  }
  .container--dashboard {
    gap: 0;
  }
}

.tailspin-black {
  filter: invert(1);
}
/* Tablets 920px*/
@media (max-width: 57.5em) {
  .team-info-box--dashboard {
    font-size: 0.875rem; /* ((1.4 * 10) / 16) */
  }
  .button--dashboard {
    font-size: 0.875rem; /* ((1.4 * 10) / 16) */
  }
  .details-outer-box--dashboard {
    font-size: 1.5rem; /* ((2.4 * 10) / 16) */
  }
  .team-info-box--dashboard {
    width: 15rem; /* ((24 * 10) / 16) */
    height: 12.5rem; /* ((20 * 10) / 16) */
  }
}

/* Smaller Tablets 780px*/
@media (max-width: 57.5em) {
  .container--dashboard {
    flex-direction: column-reverse;
    gap: 2.25rem; /* ((3.6 * 10) / 16) */
  }
  .team-outer-box--dashboard {
    gap: 1.5rem; /* ((2.4 * 10) / 16) */
  }
  .btn-change-password--dashboard {
    margin-top: -0.625rem; /* ((-1 * 10) / 16) */
  }
}

/* Mobiles 480px*/
@media (max-width: 24em) {
  .section--dashboard {
    padding: 1.75rem 0.75rem; /* ((14 * 10) / 16) */
    overflow: scroll;
  }
  .team-info-box--dashboard {
    font-size: 0.75rem; /* ((1.2 * 10) / 16) */
  }
  .details-outer-box--dashboard {
    font-size: 1.25rem; /* ((2 * 10) / 16) */
  }
  .details-outer-box--dashboard {
    row-gap: 2rem; /* ((3.2 * 10) / 16) */
    column-gap: 0.625rem; /* ((1 * 10) / 16) */
  }
}