@import url('https://fonts.googleapis.com/css2?family=Red+Rose:wght@300;400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Smaller phone screen */

@media screen and (max-width: 439px) {
    .FAQ_Main {
        background-image: url('../../../public/images/FAQ_Background.png');
        background-size: cover;
        font-family: "Red Rose", Arial, Helvetica, sans-serif;
        display: grid;
        height: 100vh;
        grid-template-areas:
            "hd"
            "mn";
    }

    .FAQ_Header {
        grid-area: hd;
        background-color: purple;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 10vh;
    }

    .FAQ_Container {
        max-width: 100vw;
        grid-area: mn;
        /* border: 2px solid green; */
        min-height: 90vh;
        display: flex;
    }

    .FAQ_Image {
        overflow: hidden;
        position: relative;
        top: -3%;
        left: 8%;
        right: 2%;
        margin-bottom: 100px;
        background-image: url('../../../public/images/FAQ_Design_Horizontal.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        /* border: 2px solid brown; */
        max-height: 10rem;
        min-width: 85vw;
        justify-content: center;
    }

    .FAQ_Question {
        min-height: 3rem;
        padding: 5px;
        width: 85vw;
        display: flex;
        color: white;
        font-size: 0.9rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url('../../../public/images/FAQ_QuestionBG.png');
        background-size: cover;
    }

    .q1 {
        position: absolute;
        top: 33%;
        left: 8%;
    }

    .q2 {
        position: absolute;
        top: 45%;
        left: 8%;
    }

    .q3 {
        position: absolute;
        top: 57%;
        left: 8%;
    }

    .q4 {
        position: absolute;
        top: 68%;
        left: 8%;
    }

    .q5 {
        position: absolute;
        top: 81%;
        left: 8%;
    }

    .FAQ_Answer {
        display: none;
        font-weight: 300;
    }

    .FAQ_Arrow {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 7.5px 0 7.5px;
        border-color: whitesmoke transparent transparent transparent;
        cursor: pointer;
        transition: transform 0.3s;
    }

    .FAQ_ArrowUp {
        transform: rotate(180deg);
    }
}

/* Wider phone screen */

@media screen and (min-width: 439px) and (max-width: 809px) {
    .FAQ_Main {
        background-image: url('../../../public/images/FAQ_Background.png');
        background-size: cover;
        height: 100vh;
        font-family: "Red Rose", Arial, Helvetica, sans-serif;
        display: grid;
        grid-template-areas:
            "hd"
            "mn";
    }

    .FAQ_Header {
        grid-area: hd;
        background-color: purple;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 10vh;
    }

    .FAQ_Container {
        max-width: 100vw;
        grid-area: mn;
        /* border: 2px solid green; */
        min-height: 90vh;
        display: flex;
    }

    .FAQ_Image {
        overflow: hidden;
        position: relative;
        top: -3%;
        left: 8%;
        right: 2%;
        margin-bottom: 100px;
        background-image: url('../../../public/images/FAQ_Design_Horizontal.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        /* border: 2px solid brown; */
        max-height: 10rem;
        min-width: 85vw;
        justify-content: center;
    }

    .FAQ_Question {
        min-height: 3rem;
        padding: 5px;
        width: 85vw;
        display: flex;
        color: white;
        font-size: 1.3rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url('../../../public/images/FAQ_QuestionBG.png');
        background-size: cover;
    }

    .q1 {
        position: absolute;
        top: 35%;
        left: 8%;
    }

    .q2 {
        position: absolute;
        top: 45%;
        left: 8%;
    }

    .q3 {
        position: absolute;
        top: 55%;
        left: 8%;
    }

    .q4 {
        position: absolute;
        top: 65%;
        left: 8%;
    }

    .q5 {
        position: absolute;
        top: 75%;
        left: 8%;
    }

    .FAQ_Answer {
        display: none;
        font-weight: 300;
    }

    .FAQ_Arrow {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 7.5px 0 7.5px;
        border-color: whitesmoke transparent transparent transparent;
        cursor: pointer;
        transition: transform 0.3s;
    }

    .FAQ_ArrowUp {
        transform: rotate(180deg);
    }
}

/* iPad screen */

@media screen and (min-width: 809px) and (max-width: 1199px){
    .FAQ_Main {
        background-image: url('../../../public/images/FAQ_Background.png');
        background-size: cover;
        height: 100vh;
        font-family: "Red Rose", Arial, Helvetica, sans-serif;
        display: grid;
        grid-template-areas:
            "hd"
            "mn";
    }

    .FAQ_Header {
        grid-area: hd;
        background-color: purple;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 10vh;
    }

    .FAQ_Container {
        max-width: 100vw;
        grid-area: mn;
        /* border: 2px solid green; */
        min-height: 90vh;
        display: flex;
    }

    .FAQ_Image {
        overflow: hidden;
        position: relative;
        top: -4%;
        left: 8%;
        right: 2%;
        margin-bottom: 50px;
        background-image: url('../../../public/images/FAQ_Design_Horizontal.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        /* border: 2px solid brown; */
        max-height: 10rem;
        min-width: 85vw;
        justify-content: center;
    }

    .FAQ_Question {
        min-height: 3rem;
        padding: 5px;
        width: 70vw;
        display: flex;
        color: white;
        font-size: 1.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url('../../../public/images/FAQ_QuestionBG.png');
        background-size: cover;
    }

    .q1 {
        position: absolute;
        top: 33%;
        left: 15%;
    }

    .q2 {
        position: absolute;
        top: 43%;
        left: 15%;
    }

    .q3 {
        position: absolute;
        top: 53%;
        left: 15%;
    }

    .q4 {
        position: absolute;
        top: 63%;
        left: 15%;
    }

    .q5 {
        position: absolute;
        top: 77%;
        left: 15%;
    }

    .FAQ_Answer {
        display: none;
        font-weight: 300;
    }

    .FAQ_Arrow {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 7.5px 0 7.5px;
        border-color: whitesmoke transparent transparent transparent;
        cursor: pointer;
        transition: transform 0.3s;
    }

    .FAQ_ArrowUp {
        transform: rotate(180deg);
    }
}

/* Desktop screen */
.FAQ_Main{
    height: 100vh;
}
@media screen and (min-width: 1199px) {
    .FAQ_Main {
        background-image: url('../../../public/images/FAQ_Background.png');
        background-size: cover;
        font-family: "Red Rose", Arial, Helvetica, sans-serif;
        display: grid;
        grid-template-areas: 
            "hd"
            "mn";
    }

    .FAQ_Header {
        grid-area: hd;
        background-color: purple;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 10vh;
    }

    .FAQ_Container {
        max-width: 100vw;
        grid-area: mn;
        /* border: 2px solid green; */
        min-height: 90vh;
        display: flex;
    }

    .FAQ_Image {
        overflow: hidden;
        position: relative;
        top: 5%;
        left: 3%;
        bottom: 5%;
        padding: 5px 5px 5px 0;
        margin-bottom: 50px;
        background-image: url('../../../public/images/FAQ_Design_Vertical.png');
        background-size: cover;
        /* border: 2px solid brown; */
        min-width: 15rem;
        max-height: 100vh;
        justify-content: center;
        align-items: center;
    }

    .FAQ_Question {
        padding-left: 0.6rem;
        width: 70vw;
        min-height: 3rem;
        margin: 1rem 1rem 2rem 1rem;
        font-size: 1.5rem;
        color: white;
        background-image: url('../../../public/images/FAQ_QuestionBG.png');
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .q1 {
        position: absolute;
        top: 22%;
        right: 5%;
    }

    .q2 {
        position: absolute;
        top: 34%;
        right: 5%;
    }

    .q3 {
        position: absolute;
        top: 46%;
        right: 5%;
    }

    .q4 {
        position: absolute;
        top: 58%;
        right: 5%;
    }

    .q5 {
        position: absolute;
        top: 74%;
        right: 5%;
    }

    .FAQ_Answer {
        display: none;
        font-weight: 300;
    }

    .FAQ_Arrow {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 7.5px 0 7.5px;
        border-color: whitesmoke transparent transparent transparent;
        cursor: pointer;
        transition: transform 0.3s;
    }

    .FAQ_ArrowUp {
        transform: rotate(180deg);
    }
}
