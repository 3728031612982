@import url('https://fonts.googleapis.com/css2?family=Red+Rose&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*.about_content {*/
/*  margin: 4rem;*/
/*  font-size: 2rem;*/
/*}*/
.about_all {
  font-family: 'Red Rose',serif;
  height: 100vh;
  background-image: url('../../../public/images/Noise\ &\ Texture-2.svg');
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0B0912;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  color: white;


  align-items: center;
  /* justify-content: center; */
}

.about_title-with-content {
  padding-bottom: 1vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 1rem;
}

.about_image-with-content {
  display: inline-flex;
}

.about_title {
  margin-right: 15vw;
  align-self: center;
  font-size: 4rem;
  color: #D09FE2;
  padding: 2rem;
  padding-bottom: 1rem;
}

.about_satellite {
  max-width: 25vw;
}

.about_bottom {
  display: inline-flex;
}

.about_para1 {
  padding-left: 2vw;
  max-width: 65vw;
}

.about_para2 {
  padding-right: 2vw;
  /* max-width: 65vw; */
  text-align: center;
  justify-content: space-between;
}
.about_moon {
  max-width: 24vw;
}

@media (max-width: 650px) {
  .about_all {
    font-size: 1rem;
  }

  .about_title {
    margin: 0;
    padding: 2%;
    align-self: center;
    font-size: 2.4rem;
    padding-bottom: 2rem;
  }
  .about_all img {
    max-width: 43vw;
  }
}

@media (max-width: 400px) {

  .about_all {
    font-size: 0.8rem;
  }

  .about_title {
    margin: 0;
    padding: 2%;
    align-self: center;
    font-size: 2rem;
    padding-bottom: 1.7rem;
  }
}

@media (max-height: 780px) {
  .about_all img {
    max-height: 30vh;
  }
  .about_title {
    font-size: 2.5rem;
    padding: 2%;
  }
  .about_content {
    font-size: 1rem;
  }
}

@media (max-height: 880px) and (max-width: 800px){
  .about_all img {
    max-height: 30vh;
  }
  .about_title {
    font-size: 2.1rem;
  }
  .about_content {
    margin: .8rem;
    font-size: .8rem;
  }
}