.first_container_login{
    background-image:url('../../../public/images/login_ss.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed; 
    display:flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height:100vh;
    width:100vw;
}
.container_login{
    display:flex;
    flex-direction: column;
    height: 80%;
    width: 40%; 
    min-width: 31.25rem;
    justify-content: center;
    align-items: center;
    background: rgba(134, 94, 255, 0.25);
    border-radius: 3.125rem;
    flex-shrink: 0;  
}

.header_login{
    color: #FFA2FD;
    font-size: 4rem;
    font-family:Red Rose;
    font-weight: 400;
}
.middle_login{
    margin-right: 1.875rem;
    margin-top: 4.375rem;
    margin-bottom: 1.875rem;
}
.detail_login{
    color: #E383FF;
    font-size: 1.25rem;
    font-family: Red Rose;
    font-weight: 400;
    margin-bottom: 1.25rem;
}
.passdetail_login{
    color: #E383FF;
    font-size: 1.25rem;
    font-family: Red Rose;
    font-weight: 400;
    margin-top: 2.5rem;
    margin-bottom: 1.25rem;
}
.enterdetails_login{
    background: rgba(134, 94, 255, 0);
    border:0rem;
    border-bottom:0.625px #E383FF solid ;
    outline:none;
     width:21.875rem; 
     color:#E383FF;
     font-size: large;
}
.account_login{
    color: #865EFF;
    font-size:1.438rem;;
    font-family: Poppins;
    font-weight: 400;
    margin-bottom: -3.755rem;
}
.seperate_login{
    color: #E383FF;
    font-size: 1.438rem;
    font-family: Poppins;
    font-weight: 700;
}
.buttons_login{
    background-color: #E383FF;
    color:#8262FF;
    width: 15.313rem;
    height:3.188rem;
    border:none;
    border-radius: 1.563rem;
    margin-bottom: 1.563rem;
}
@media (min-width:389px) and (max-width:700px){
    .container_login{
        min-width: 12.5rem;
        width:24.75rem;
         height:40rem; 
    }
    
    .buttons_login{
        font-size:1.875rem;
    }
    .account_login{
        font-size:1.25rem;
    }
}
@media (max-width:391px) and (min-width:260px){
    .container_login{
        min-width: 12.5rem;
        width:90%;
        height:37.5rem;
    }
    .buttons_login{
        font-size:1.875rem ;
    }

    .seperate_login{
        font-size:1.1rem;
    }
    .account_login{
        font-size:1.1rem;
        margin-left: 1.25rem;
        margin-top: -0.425rem;
    }
    .header_login{
        font-size: 3.375rem;
        margin-top: -2.25rem;
    }
    .enterdetails_login{
        width:100%;
    }

}