
@import url('https://fonts.googleapis.com/css2?family=Red+Rose&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html,
body {
  margin: 0px;
  width: 100%;
  height: 100%;
}

.submission-page {
  background-color: #191825;
  width: 100%;
  height: 100%;
  position: absolute;
}

.gears {
  position: absolute;
  mix-blend-mode: overlay;
  top: 0;
  left: 0;
}

.gradient-box {
  position: absolute;
  width: 100%;
  height: 25%;
  top: 60%;
  left: 0;
  background-color: #865dff;
  filter: blur(250px);
}

.submission-box-background {
  width: 60%;
  height: 100%;
  left: 20%;
  position: absolute;
  background-color: #d9d9d9;
  mix-blend-mode: multiply;
}

.submission-box {
  width: 60%;
  height: 90%;
  top: 10%;
  left: 20%;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-family: 'Red Rose', serif;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 400;
  font-size: 4vw;
}

.submission-title {
  margin-top: 1.5%;
}

.inputs {
  margin-top: 5%;
  display: grid;
  grid-template-columns: 12vw 35vw;
  row-gap: 6vh;
  column-gap: 3vw;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 200;
  font-size: 1.45vw;
}

input[type=text],input[type=url] {
  border: 0;
  outline: 0;
  border-bottom: 2px solid #FFFFFF;
  font-size: 1.45vw;
  background-color: rgba(255,255,255,0);
  color: #FFFFFF;     
}

input[type=submit] {
  margin-left: 35%;
  margin-top: 5vh;
  width: 30%;
  height: 12.5%;
  border-radius: 18px;
  background-color: #FFFFFF;
  border: transparent;
  color: #000000;
  cursor: pointer;
  font-family: 'Red Rose', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.45vw;
}

input[type=submit]:hover {
  mix-blend-mode: overlay;
}

.header-bar {
  display: none;
}

.footer-bar {
  display: none;
}


@media only screen and (max-width: 700px) {

  .submission-box {
    width: 60%;
    height: 65%;
    top: 15%;
    left: 20%;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-family: 'Red Rose', serif;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 4vw;
  }
  
  .inputs {
    width: 80%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    column-gap: 2vw;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: 'Poppins', sans-serif;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 200;
    font-size: 1.5vw;
  }

  .submission-box-background {
    width: 80%;
    height: 60%;
    left: 10%;
    top: 20%;
    border-radius: 30px; 
  }

  .submission-box {
    width: 80%;
    height: 60%;
    left: 10%;
    top: 20%;
    border-radius: 30px;
  }

  .submission-title {
    font-size: 3.5vh;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }

  input[type=text],input[type=url] {
    width: auto;
    font-size: 2vh;
  }

  .inputs {
    width: 80%;
    row-gap: 2vh;
    column-gap: 0;
    font-size: 2vh;
  }

  input[type=submit] {
    border-radius: 16px;
    margin-left: 20%;
    width: 60%;
    height: 10%;
    font-size: 2vh;
  }
  
}
