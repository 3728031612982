.outer_container_signup{
    background-image:url('../../../public/images/login_ss.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display:flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height:100vh;
    width:100vw;
    padding:0;
    margin:0;
}
.inner_container_signup{
    display:flex;
    flex-direction: column;
    height: 85%;
    width: 40%; 
    min-width: 31.25rem;
    justify-content: center;
    align-items: center;
    background: rgba(134, 94, 255, 0.25);
    border-radius: 5rem;
    flex-shrink: 0;
    margin-top: -4.2rem;
}

.header_signup{
    color: #FFA2FD;
    font-size: 2.5rem;
    font-family:Red Rose;
    font-weight: 400;
    margin-bottom: 1.0625rem;
    margin-top: 4.2rem;
}
.middle_signup{
    margin-bottom: 0;
}
.detail_signup{
    color: #E383FF;
    font-size: 1rem;
    font-family: Red Rose;
    font-weight: 400;
    margin-bottom: .3rem;
}
.passdetail_signup{
    color: #E383FF;
    font-size: 1rem;
    font-family: Red Rose;
    font-weight: 400;
    margin-bottom: .3rem;
    
}
.enterdetails_signup{
    background: rgba(134, 94, 255, 0);
    border:0rem;
    border-bottom:0.0625rem #E383FF solid ;
    outline:none;
     width:21.875rem; 
     color:#E383FF;
     font-size: large;
     margin-bottom: .7rem;
}
.account_signup{
    color: #865EFF;
    font-size: 1.4375rem;
    font-family: Poppins;
    font-weight: 400;
    margin-bottom: 4.375rem;
}
.seperate_signup{
    color: #E383FF;
    font-size: 1rem;
    font-family: Poppins;
    font-weight: 700;
}

.buttons_signup{
    background-color: #E383FF;
    color:#8262FF;
    width: 15.3125rem;
    height:3.1875rem;
    border:none;
    border-radius: 2.5rem;
    margin-bottom: 1.25rem;
}
.buttons_signup:hover{
    background-color: #865EFF;
    color:#E383FF;
    width: 15.3125rem;
    height:3.1875rem;
    border:none;
    border-radius: 2.5rem;
    margin-bottom: 1.25rem;
}
@media (max-width:700px) and (min-width:389px){
    .inner_container_signup{
        min-width: 12.5rem;
        width:24.375rem;
        height:45.5rem;
        margin-top: -4.2rem;
    }
    .buttons_signup{
        font-size:1.2rem ;
    }
    .account_signup{
        font-size:1rem ;
        margin-top: -2rem;
    }

    .detail_signup{
        font-size: 1rem;
    }
    .header_signup{
        margin-top: 40%;
        font-size: 2.5rem;
    }
    .passdetail_signup{
        font-size:1rem ;
    }

    .seperate_signup{
        font-size:1rem;
    }
}
@media (max-width:388px) and (min-width:260px){
    .inner_container_signup{
        min-width: 12.5rem;
        width:90%;
        height:35.5rem;
        margin-top: -2.25rem;
    }
    .passdetail_signup{
        font-size:1rem ;
    }
    .buttons_signup{
        font-size:1.875rem ;
    }
    .account_signup{
        font-size:.9375rem;
        /*margin-left: 2.2rem;*/
        /*margin-top: -12rem;*/

    }

    .seperate_signup{
        font-size:.9375rem;
        margin-left: 2.2rem;
    }
    .header_signup{
        margin-top: 80%;
        font-size: 2rem;
    }

    .detail_signup{
        font-size: .9375rem;
    }

    .enterdetails_signup{
        width:100%;
        margin-bottom: .5%;
    }

}
