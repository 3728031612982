* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.foot_container-main {
  display: flex;
  /*position: fixed;*/
  width: 100vw;
  bottom: 0;
  height: 9.3rem;
  background: rgba(85, 71, 123, 0.91);
  margin-top: 100vh;
}
.foot_vectorsvg {
  max-height: 9.2rem;
}
.foot_container1 {
  display: flex;
  align-items: center;
  margin-left: 2.5rem;
}

.footer_Exodus {
  font-size: 2.2rem;
  margin-left: 0.2rem;
  color: #FFFFFF;

}

.foot_container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  transform: translateY(25%);
  font-family: "Red Rose", serif;
  margin-left: auto;
  margin-right: auto;
}

.foot_social {
  display: flex;
  gap: 3rem;

}

.foot_follow-text {
  font-size: 2rem;
  color: #FFFFFF;
}

.foot_container3 {
  display: flex;
  align-items: center;
}

.foot_container3 a {
  font-size: 2rem;
  color: #FFFFFF;
  font-family: "Red Rose", serif;
  text-decoration: none;
  margin-right: 4.5rem;


}

.foot_container3 a:hover {
  color: cyan;
}
@media (min-width:319px) and (max-width:480px){


  .foot_container-main {
          display: flex;
          position: fixed;
          bottom: 0;
          height: 5rem;
          background: rgba(85, 71, 123, 0.91);
          margin-top: 100vh;
      }
              .foot_container1 {
                  display: flex;
                  align-items: center;
                  margin-left: 0.5rem;
              }
              .footer_Exodus {
                  font-size: 0.9rem;
                  margin-left: 0.01rem;
                  color: #FFFFFF;
      
              }
                              
              .foot_vectorsvg{
                  width: 4rem;
              }
.foot_social {
  display: flex;
  gap: 0.7rem;
}
.foot_social img {
  width: 1.3rem;
}

.foot_follow-text {
  font-size: 0.9rem;
  color: #FFFFFF;
  
}

.foot_container3 {
  display: flex;
  align-items: center;
}

.foot_container3 a {
  font-size: 0.9rem;
  color: #FFFFFF;
  font-family: "Red Rose", serif;
  text-decoration: none;
  margin-right: 3rem;


}
}
@media (min-width:481px) and (max-width:1024px) {


  .foot_container-main {
      display: flex;
      position: fixed;
      bottom: 0;
      height: 7rem;
      background: rgba(85, 71, 123, 0.91);
      margin-top: 100vh;
  }

  .foot_container1 {
      display: flex;
      align-items: center;
      margin-left: 2rem;
  }

  .footer_Exodus {
      font-size: 2rem;
      margin-left: 0.01rem;
      color: #FFFFFF;

  }

  .foot_vectorsvg {
      width: 4rem;
  }

  .foot_social {
      display: flex;
      gap: 2rem;
  }

  .foot_social img {
      width: 2rem;
  }

  .foot_follow-text {
      font-size: 2rem;
      color: #FFFFFF;

  }

  .foot_container3 {
      display: flex;
      align-items: center;
  }

  .foot_container3 a {
      font-size: 2rem;
      color: #FFFFFF;
      font-family: "Red Rose", serif;
      text-decoration: none;
      margin-right: 3rem;
}}
